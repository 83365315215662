.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0 !important;
  color: blue;
}

.tbStyle {
  width: 100%;
  text-align: center;

  .thead {
    font-weight: bold;
  }

  .tbody {
    text-align: justify;

    .hide-row-hover:hover {
      background-color: inherit;
      opacity: 1;
    }

    .hide-column-hover:not(:last-child) {
      background-color: white;
      opacity: 1;
    }

    .row-seperator {
      height: 2px;
      background-color: lightgray;
      padding: 0px;
    }

    .edited {
      background-color: lavender;
    }

    .disabled-pointer-events {
      pointer-events: none;
    }

    .dropdown-item {
      text-align: left;
    }

    .dropdown-toggle {
      text-align: left;
    }
  }
}

.tbStyle1 {
  width: 100%;
  vertical-align: middle;

  .btn1 {
    padding: 0%;
    margin-top: -5px;

    .icon1 {
      font-size: 14px;
      color: green;
    }
  }

  .progressbar1 {
    padding: 0%;
    margin: 0%;
    margin-top: 5px;
  }

  .thead {
    font-weight: bold;
  }

  .tbody {
    text-align: justify;

    .hide-row-hover:hover {
      background-color: inherit;
      opacity: 1;
    }

    .hide-column-hover:not(:last-child) {
      background-color: white;
      opacity: 1;
    }

    .row-seperator {
      height: 2px;
      background-color: lightgray;
      padding: 0px;
    }

    .edited {
      background-color: lavender;
    }

    .disabled-pointer-events {
      pointer-events: none;
    }

    .dropdown-item {
      text-align: left;
    }

    .dropdown-toggle {
      text-align: left;
    }
  }
}

.tbStyle2 {
  // width: 100%;
  vertical-align: top;
  text-align: left;
  border: 0px;
}

.rowStyle {
  align-items: center;

  .colBtn {
    text-align: end;
  }
}

.rowStyle1 {
  border: 0px;
}

.react-tags__search-input {
  width: 100%;
}

// .react-tags__selected {

// }

.selectedTag {
  background-color: blueviolet;
  color: white;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  min-width: 45px;
  margin: 4px 2px;
}

.suggestionTag {
  background-color: blueviolet;
  color: white;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  min-width: 45px;
  margin: 4px 2px;
}

.tbLite {
  border: 3px solid rgba(0, 128, 255, 0.9) !important;
  // border-top-left-radius: 15px;
  // border-top-right-radius: 15px;
  // border-bottom-left-radius: 15px;
  // border-bottom-right-radius: 15px;

  tr td {
    padding: 2px !important;
    margin: 0px !important;
    vertical-align: middle;
    border: 1px solid rgba(0, 128, 255, 0.9);
  }

  .qs-content-picture-td {
    width: 50px;
    text-align: center;
    // padding-right: 6px !important;
  }

  img {
    pointer-events: initial;
  }
}

.tb-no-padding {
  width: 100%;
  // background-color: white;
  td {
    padding: 0px !important;
  }
}
.tb-no-padding:hover {
  background-color: white;
}

.td-no-border {
  border-style: none;
  // border: 0px solid white;
  border-width: 0px;
}

.tb-display-mode {
  width: 100%;
  font-size: 12px;
  // font-weight: bold;
  text-align: center;
  td {
    padding: 0px 1px !important;
    button {
      height: 25px;
      padding-top: 2px;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.dropdown-menu {
  width: 90%;

  .dropdown-item {
    // font-style: italic;
    text-align: center;
  }
}

.subjectTag {
  background-color: orange;
}

.customTooltipStyle {
  .tooltip-inner {
    max-width: 500px;
    text-align: left;
  }
}

// .qListItem-title {
//     color: white;
//     background-color: transparent;
//     font-weight: bold;
//     border: 0px solid white;
// }
.qListItem-normal {
  cursor: pointer;
  color: black;
  background-color: white;
  font-weight: normal;
}
.qListItem-normal:hover {
  cursor: pointer;
  color: black;
  background-color: orange;
  font-weight: normal;
}
.qListItem-disabled {
  cursor: not-allowed;
  color: white;
  background-color: lightgray;
  font-weight: normal;
}
.qListItem-selected {
  cursor: pointer;
  color: white !important;
  background-color: blueviolet !important;
  font-weight: bold;
}

.floating-div-corner-lower-left {
  position: fixed;
  bottom: 5px;
  left: 20px;
  z-index: 100;
  text-align: center;
  padding: 5px;

  .content {
    padding: 5px;
    border: 2px solid orange;
    border-radius: 10px;
    background-color: white;
    width: fit-content;
    height: fit-content;

    table {
      text-align: center;

      thead > tr > th {
        vertical-align: baseline;
      }
      tbody > tr > td {
        width: 30px;

        .rr-btn {
          width: 100%;
        }
      }
    }
  }
}

//2024.06.27
.floating-div-qs-list-hover {
  position: fixed;
  bottom: 5px;
  right: 10px;
  z-index: 100;
  text-align: center;
  padding: 5px;

  .hover-title {
    text-align: center;
    transform: rotate(90deg);
  }
}

//2024.06.27
.floating-div-qs-list-show {
  position: fixed;
  bottom: 5px;
  right: 10px;
  z-index: 100;
  text-align: center;
  padding: 5px;

  .content {
    padding: 5px;
    border: 2px solid orange;
    border-radius: 10px;
    background-color: white;
    width: fit-content;
    height: fit-content;

    table {
      text-align: center;

      thead > tr > th {
        vertical-align: baseline;
      }
      tbody > tr > td {
        width: 30px;

        .rr-btn {
          width: 100%;
        }
      }
    }
  }
}

.tb-edit-event-room {
  width: 100%;
  // border-top: hidden;
  // border: '0px solid black';
  padding: 5px;
  text-align: center;

  .left {
    text-align: right;
    width: 45%;
  }
  .right {
    text-align: left;
    width: 45%;
    font-weight: bold;
  }

  td {
    hr {
      margin: 5px;
    }
  }
}

.tb-search-room {
  width: 100%;
  border: 1px solid gray;
  margin-top: 5px;

  .title {
    td {
      padding: 10px 0px 0px 0px;
      font-weight: bold;
    }
    .left {
      text-align: left;
    }
    .action {
      // text-align: right;
      width: fit-content;
    }
  }

  .item {
    // td {
    //     padding: 10px 0px 15px 0px;
    // }
    .left {
      text-align: left;
      padding: 0px;
      width: fit-content;
    }
    .action {
      // text-align: right;
      width: fit-content;
    }
  }

  .result {
    text-align: left;
    td {
      padding: 5px 0px 5px 10px;
    }
    .title {
      font-weight: bold;
    }
    .action {
      width: fit-content;
      vertical-align: middle;
      text-align: center;
    }
  }

  .result-not-match {
    td {
      padding: 5px;
      border: 1px solid black;
      background-color: slategray;
      color: white;
    }
  }
  .result-match {
    td {
      padding: 5px;
      border: 1px solid black;
      background-color: cornflowerblue;
      color: white;
    }
  }
}

.tbStyle_DocRoomTable1 {
  // width: 50%;
  // height: fit-content;
  text-align: center;
  padding: 5px;
  margin: 10px;
  // align-self: center;
  // border: 1px solid black;

  .title {
    text-align: left;
    height: 30px;
  }
  .value {
    text-align: left;
    height: 30px;
  }
}

.tbStyle_DocRoomTable2 {
  width: 100%;
  text-align: center;

  thead {
    font-weight: bold;

    tr {
      .student {
        text-align: left;
      }
    }
  }

  tbody {
    .hide-row-hover:hover {
      background-color: inherit;
      opacity: 1;
    }

    .hide-column-hover:not(:last-child) {
      background-color: white;
      opacity: 1;
    }

    tr {
      text-align: center;

      .student {
        text-align: left;
      }
    }

    tr:hover td {
      background-color: #efefef;
    }
  }
}

.tb-no-border {
  tr {
    border: hidden;
  }
}

.grid-container-2-col {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
}
.grid-item-2-col {
  padding: 5px;
  text-align: center;
  width: 200px;

  .btn {
    width: 180px;
  }
}

.row-lang {
  .label {
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    padding-left: 20px;
  }
  .content {
    padding-left: 0px;
  }
}

.dropdown {
  .dropdown-toggle {
    width: 100%;
  }
  .dropdown-menu {
    width: fit-content;
    .dropdown-item {
      padding: 5px 10px;
    }
  }
}

.modal-xl {
  width: 90%;
  max-width: 1200px;
}

dl > dd {
  width: 80px;
  text-align: center;
  margin-top: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.select-organizer-btn .dropdown-toggle {
  text-wrap: wrap;
}
